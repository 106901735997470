import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Icon } from '@sb1/ffe-icons-react';
import { ActionButton, PrimaryButton, SecondaryButton, TertiaryButton, ExpandButton, InlineExpandButton, ShortcutButton, TaskButton, BackButton } from '@sb1/ffe-buttons-react';
import { Table, TableHead, TableBody, TableHeaderCell, TableRow, TableDataCell } from '@sb1/ffe-tables-react';
export const _frontmatter = {
  "title": "Knapper",
  "template": "default",
  "introtext": "Knapper gir brukerne mulighet for å gjøre ulike typer handlinger i brukergrensesnittet"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const CheckList = makeShortcode("CheckList");
const CheckListItem = makeShortcode("CheckListItem");
const DSExample = makeShortcode("DSExample");
const UUTestReport = makeShortcode("UUTestReport");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Bruk av knapper`}</h2>
    <p>{`I SpareBank 1 har vi flere typer knapper og hvilken du skal bruke kommer an på situasjonen, og hvilke andre knapper som ligger i «nærheten». Felles for alle knappene er at de kan brukes i situasjoner der du vil gi brukerne muligheten til å utføre ulike typer handlinger i brukergrensesnittet. `}</p>
    <p>{`Noen eksempler på scenarioer der knapper kan komme til nytte:`}</p>
    <ul>
      <li parentName="ul">{`Starte en prosess. F.eks. «Bli kunde», «Sjekk pris», «Meld skade»`}</li>
      <li parentName="ul">{`Gi en kommando til systemet. F.eks. «Skriv ut», «Last ned», «Betal», «Bestill»`}</li>
      <li parentName="ul">{`Beslutte noe. F.eks. «Kjøp», «Aksepter», «Overfør»`}</li>
      <li parentName="ul">{`Navigere i brukergrensesnittet. F.eks. «Neste», «Fortsett», «Søk»`}</li>
      <li parentName="ul">{`Endre modus eller visning. F.eks. «Redigér» for å gå til redigeringsmodus, «Vis flere» for å ekspandere en liste`}</li>
    </ul>
    <p>{`Knapper skal brukes der kundene utfører en handling. Der de skal navigere til andre sider og sider utenfor løsningen brukes `}<a parentName="p" {...{
        "href": "/komponenter/typografi/"
      }}>{`lenker`}</a>{` istedenfor. Når du vil at brukeren skal ta et valg er det best å bruke `}<a parentName="p" {...{
        "href": "/komponenter/skjemaelementer/"
      }}>{`radioknapper`}</a>{` eller `}<a parentName="p" {...{
        "href": "/komponenter/skjemaelementer/"
      }}>{`checkboxer`}</a>{`, for å så bekrefte med en knapp.`}</p>
    <div className="sb1ds-layout-2col-grid">
    <div>
        <h3 className="ffe-h4">Gjør sånn:</h3>
        <CheckList mdxType="CheckList">
            <CheckListItem mdxType="CheckListItem">Knappen skal brukes til å utføre en handling</CheckListItem>
            <CheckListItem mdxType="CheckListItem">Ha informativ og beskrivende tekst, ikon eller begge deler</CheckListItem>
            <CheckListItem mdxType="CheckListItem">Bruk verb i imperiativ form (unntak: konvensjoner som «neste»)</CheckListItem>
            <CheckListItem mdxType="CheckListItem">Ha så kort tekst som mulig</CheckListItem>
        </CheckList>
    </div>
    <div>
    <h3 className="ffe-h4">Ikke sånn:</h3>
       <CheckList mdxType="CheckList">
            <CheckListItem isCross={true} mdxType="CheckListItem">Ha tekst som går over 2 linjer</CheckListItem>
            <CheckListItem isCross={true} mdxType="CheckListItem">Gjem knappen eller sett den som disabled</CheckListItem>
            <CheckListItem isCross={true} mdxType="CheckListItem">Ha flere primærknapper på en side</CheckListItem>
            <CheckListItem isCross={true} mdxType="CheckListItem">Bruk knapp til å navigere utenfor løsningen (bruk lenke istedenfor)</CheckListItem>
            <CheckListItem isCross={true} mdxType="CheckListItem">Bruk knapp til å la bruker ta valg (bruk checkbox/radioknapp istedenfor)</CheckListItem>
        </CheckList>
    </div>
    </div>
    <h2>{`Deaktiverte knapper`}</h2>
    <p>{`I SpareBank 1 bruker vi ikke deaktiverte versjoner av knappene (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`disabled`}</code>{`). Istedenfor en deaktivert knapp, la knappen alltid være synlig og vis gode feilmeldinger hvis brukeren prøver å trykke på knappen før betingelsene for å bruke knappen er innfridd.`}</p>
    <h2>{`Valg av knapp`}</h2>
    <p>{`I SpareBank 1 har vi kommandoknapper som kan brukes til alle typer handlinger, og spesielle knapper som kun skal brukes i spesifikke situasjoner.`}</p>
    <p>{`For å velge mellom kommandoknappene ActionButton, PrimaryButton, SecondaryButton og TertiaryButton, må du vurdere handlingens prioritet. Prioriteten vurderes kvalitativt basert på viktighet og frekvens:`}</p>
    <ul>
      <li parentName="ul">{`En handling som gjøres sjelden, men har svært høy viktighet enten for brukeren eller SpareBank 1, bør få høy eller svært høy prioritet. F.eks. knapper for å starte en prosess eller beslutte noe, som "Bli kunde", "Meld skade" og "Kjøp".`}</li>
      <li parentName="ul">{`En handling som har lav eller middels viktighet, men høy bruksfrekvens vil kunne få høy eller middels prioritet (men aldri svært høy). F.eks. knapper som er del av en prosess, som "Legg til godkjenning" og "Fortsett".`}</li>
      <li parentName="ul">{`En handling som sjelden brukes og har lav viktighet, men fortsatt må være tilgjengelig, vil som regel få lav prioritet. F.eks. å hente opp et klageskjema.`}</li>
    </ul>
    <p>{`Valg av kommandoknapp må også vurderes relativt avhengig av hvilke andre knapper som er i umiddelbar nærhet. F.eks. vil kanskje en lagre-knapp gis høy prioritet, mens avbryt-knappen ved siden av gis lav prioritet (selv om den brukes relativt ofte).`}</p>

    <Table className="sb1ds-button-table" mdxType="Table">
    <TableHead mdxType="TableHead">
        <TableRow mdxType="TableRow">
            <TableHeaderCell scope="col" mdxType="TableHeaderCell">
                Knapp
            </TableHeaderCell>
            <TableHeaderCell scope="col" mdxType="TableHeaderCell">
                Bruksområde
            </TableHeaderCell>
        </TableRow>
    </TableHead>
    <TableBody mdxType="TableBody">
        <TableRow mdxType="TableRow">
            <TableDataCell mdxType="TableDataCell">
                <ActionButton onClick={f => f} mdxType="ActionButton">ActionButton</ActionButton>
            </TableDataCell>
            <TableDataCell mdxType="TableDataCell">
                Svært høyt prioriterte handlinger (call to action) som krever en
                knapp som skiller seg ut fra de andre knappene. Det skal som
                hovedregel kun være én ActionButton per side/view.
            </TableDataCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <TableDataCell mdxType="TableDataCell">
                <PrimaryButton onClick={f => f} mdxType="PrimaryButton">PrimaryButton</PrimaryButton>
            </TableDataCell>
            <TableDataCell mdxType="TableDataCell">Høyt prioriterte handlinger.</TableDataCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <TableDataCell mdxType="TableDataCell">
                <SecondaryButton onClick={f => f} mdxType="SecondaryButton">
                    SecondaryButton
                </SecondaryButton>
            </TableDataCell>
            <TableDataCell mdxType="TableDataCell">
                Middels prioriterte handlinger. Kan ha ikon.
            </TableDataCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <TableDataCell mdxType="TableDataCell">
                <TertiaryButton onClick={f => f} mdxType="TertiaryButton">TertiaryButton</TertiaryButton>
            </TableDataCell>
            <TableDataCell mdxType="TableDataCell">
                Lavt prioriterte handlinger. Kan ha ikon.
            </TableDataCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <TableDataCell mdxType="TableDataCell">
                <ExpandButton onClick={f => f} isExpanded={false} mdxType="ExpandButton">
                    ExpandButton
                </ExpandButton>
            </TableDataCell>
            <TableDataCell mdxType="TableDataCell">
                Brukes kun for den spesifikke handlingen å ekspandere/kollapse
                en seksjon.
            </TableDataCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <TableDataCell mdxType="TableDataCell">
                <InlineExpandButton onClick={f => f} isExpanded={false} mdxType="InlineExpandButton">
                    InlineExpandButton
                </InlineExpandButton>
            </TableDataCell>
            <TableDataCell mdxType="TableDataCell">
                Brukes kun for den spesifikke handlingen å ekspandere/kollapse
                en seksjon fra en linje med tekst.
            </TableDataCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <TableDataCell mdxType="TableDataCell">
                <ShortcutButton onClick={f => f} mdxType="ShortcutButton">ShortcutButton</ShortcutButton>
            </TableDataCell>
            <TableDataCell mdxType="TableDataCell">
                Brukes kun som snarvei for å navigere til et annet sted i
                løsningen. Alternativ til <a href="#!/LinkText">Lenke</a>.
            </TableDataCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <TableDataCell mdxType="TableDataCell">
                <TaskButton icon={<Icon size="sm" fileUrl="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgLTk2MCA5NjAgOTYwIiB3aWR0aD0iMjAiPjxwYXRoIGQ9Ik00NTQuMDAxLTQ1NC4wMDFoLTE2OHEtMTEuMDUgMC0xOC41MjUtNy40MTgtNy40NzUtNy40MTgtNy40NzUtMTguMzg0IDAtMTAuOTY2IDcuNDc1LTE4LjU4MXQxOC41MjUtNy42MTVoMTY4di0xNjhxMC0xMS4wNSA3LjQxOC0xOC41MjUgNy40MTgtNy40NzUgMTguMzg0LTcuNDc1IDEwLjk2NiAwIDE4LjU4MSA3LjQ3NXQ3LjYxNSAxOC41MjV2MTY4aDE2OHExMS4wNSAwIDE4LjUyNSA3LjQxOCA3LjQ3NSA3LjQxOCA3LjQ3NSAxOC4zODQgMCAxMC45NjYtNy40NzUgMTguNTgxdC0xOC41MjUgNy42MTVoLTE2OHYxNjhxMCAxMS4wNS03LjQxOCAxOC41MjUtNy40MTggNy40NzUtMTguMzg0IDcuNDc1LTEwLjk2NiAwLTE4LjU4MS03LjQ3NXQtNy42MTUtMTguNTI1di0xNjhaIi8+PC9zdmc+" mdxType="Icon" />} mdxType="TaskButton">TaskButton</TaskButton>
            </TableDataCell>
            <TableDataCell mdxType="TableDataCell">
                Kan brukes som alternativ til en kommandoknapp for handlingen å
                legge til noe.
            </TableDataCell>
        </TableRow>
        <TableRow mdxType="TableRow">
            <TableDataCell mdxType="TableDataCell">
                <BackButton onClick={f => f} mdxType="BackButton">Tilbake</BackButton>
            </TableDataCell>
            <TableDataCell mdxType="TableDataCell">
                Brukes bare der man vil gå tilbake til forrige steg/side
            </TableDataCell>
        </TableRow>
    </TableBody>
    </Table>
    <h2>{`ActionButton`}</h2>
    <p>{`Brukes for svært høyt prioriterte handlinger (call to action), og der du trenger en knapp som skiller seg ut. Det skal som hovedregel kun være en ActionButton per side/view.`}</p>
    <DSExample name="buttons_ActionButton" mdxType="DSExample" />
    <UUTestReport reportID="Buttons_ActionButton" mdxType="UUTestReport" />
    <h2>{`PrimaryButton`}</h2>
    <p>{`For høyt prioriterte handlinger, som det også kan finnes flere av på en side/view.`}</p>
    <DSExample name="buttons_PrimaryButton" mdxType="DSExample" />
    <UUTestReport reportID="Buttons_PrimaryButton" mdxType="UUTestReport" />
    <h2>{`SecondaryButton`}</h2>
    <p>{`Kan brukes der du har middels prioriterte handlinger, eller i kombinasjon med andre knapper, for eksempel som en «avbryt»-knapp ved siden av en ActionButton.
SecondaryButtons kan ha ikon. `}</p>
    <DSExample name="buttons_SecondaryButton" mdxType="DSExample" />
    <UUTestReport reportID="Buttons_SecondaryButton" mdxType="UUTestReport" />
    <h2>{`TertiaryButton`}</h2>
    <p>{`For lavt prioriterte handlinger, eller i situasjoner der du trenger å «linke» til innhold på samme side. For eksempel «anchors» som leder deg til et avsnitt i en artikkel.`}</p>
    <DSExample name="buttons_TertiaryButton" mdxType="DSExample" />
    <UUTestReport reportID="Buttons_TertiaryButton" mdxType="UUTestReport" />
    <h2>{`ExpandButton`}</h2>
    <p>{`Brukes i spesifikke situasjoner der du vil ekspandere/kollapse en seksjon.`}</p>
    <DSExample name="buttons_ExpandButton" mdxType="DSExample" />
    <UUTestReport reportID="Buttons_ExpandButton" mdxType="UUTestReport" />
    <h2>{`InlineExpandButton`}</h2>
    <p>{`Samme som ExpandButton, bortsett fra at denne brukes når du vil ekspandere/kollapse en seksjon fra en linje med tekst.`}</p>
    <DSExample name="buttons_InlineExpandButton" mdxType="DSExample" />
    <UUTestReport reportID="Buttons_InlineExpandButton" mdxType="UUTestReport" />
    <h2>{`BackButton`}</h2>
    <p>{`Brukes der du vil gi brukerne muligheten til å navigere tilbake dit de var.`}</p>
    <DSExample name="buttons_BackButton" mdxType="DSExample" />
    <UUTestReport reportID="Buttons_BackButton" mdxType="UUTestReport" />
    <h2>{`ShortcutButton`}</h2>
    <p>{`Et alternativ til lenke der du ønsker å tilby en snarvei til et annet sted i løsningen.`}</p>
    <DSExample name="buttons_ShortcutButton" mdxType="DSExample" />
    <UUTestReport reportID="Buttons_ShortcutButton" mdxType="UUTestReport" />
    <h2>{`TaskButton`}</h2>
    <p>{`Kan brukes som et alternativ til en kommandoknapp i scenarioer der man ønsker å legge til noe.`}</p>
    <DSExample name="buttons_TaskButton" mdxType="DSExample" />
    <UUTestReport reportID="Buttons_TaskButton" mdxType="UUTestReport" />
    <h2>{`ButtonGroup`}</h2>
    <p>{`ButtonGroup hjelper deg med å samle alle knappene å få de på en rad. På mindre skjermer vil knappene vises under hverandre.`}</p>
    <DSExample name="buttons_ButtonGroup" mdxType="DSExample" />
    <UUTestReport reportID="Buttons_ButtonGroup" mdxType="UUTestReport" />
    <h3>{`thin`}</h3>
    <p>{`Der knappene går over flere linjer, vil «thin» gjøre avstanden mellom radene mindre.`}</p>
    <DSExample name="buttons_ButtonGroup_thin" mdxType="DSExample" />
    <h3>{`inline`}</h3>
    <p>{`Inline endrer ButtonGroup fra «display: flex» til inline, som betyr at gruppen kun vil ta opp plassen den trenger og ikke 100% av bredden som ButtonGroup gjør. Brukes i situasjoner der du har annet innhold som skal ligge rundt eller på samme linje som knappene. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      